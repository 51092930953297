<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    @before-open="beforeOpen"
    :name="modalName"
  >
    <div class="modal--wrapper photo--section">
      <div v-if="!withdrawalRequest || !withdrawalRequest.receipt_photo">
        <div class="modal--title">
          {{ $t('withdrawalRequest.modal.title') }}
        </div>
        <!--ini preview gambar-->
        <div class="photo--preview-wrapper" v-if="receiptPhoto">
          <photo-preview
            :length="receiptPhoto ? 1 : 0"
            :photo="receiptPhoto"
            :isFloorPlan="false"
            :noPrimary="true"
            removePhotoDispatch="withdrawalRequest/REMOVE_RECEIPT_PHOTO"
          />
        </div>
        <vue-dropzone
          v-show="false"
          ref="photoDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="added"
          @vdropzone-sending="sending"
          @vdropzone-success="success"
          @vdropzone-complete="completed"
        />
        <div class="d-flex align-center justify-center mt-4">
          <v-btn
            type="button"
            color="primary"
            :disabled="receiptPhoto != null"
            class="btn btn-primary--light btn-block"
            @click="triggerDropzone"
          >
            {{ $t('withdrawalRequest.btn.upload') }}
          </v-btn>
        </div>
        <div
          class="d-flex align-center justify-center mt-4"
          v-if="withdrawalRequest && withdrawalRequest.status === 'PENDING'"
        >
          <v-btn color="primary" class="bottom--button" @click="accept">
            {{ $t('withdrawalRequest.btn.withdraw') }}
          </v-btn>
        </div>
      </div>
      <div v-else>
        <div class="modal--title">
          {{ $t('withdrawalRequest.btn.seeProof') }}
        </div>
        <simple-photo-preview :photos="[withdrawalRequest.receipt_photo]"></simple-photo-preview>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const SimplePhotoPreview = () => import('@/components/utils/simple-photo-preview');
const PhotoPreview = () => import('@/components/project-management/project/form/photo-preview.vue');
import vue2Dropzone from 'nuxt-dropzone';

export default {
  name: 'modal-withdrawal',
  mixins: [HelperMixin],
  components: {
    SimplePhotoPreview,
    PhotoPreview,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      modalName: 'modal-withdrawal',
      withdrawalRequest: null,
      dropzoneOptions: {
        url: `${this.$store.state.global.apiUrl}/api/file_content/upload_photo`,
        thumbnailWidth: 150,
        maxFilesize: 5,
        thumbnailMethod: 'contain',
        acceptedFiles: '.jpg,.png,.jpeg',
        // headers: {'X-CSRF-TOKEN': self.csrfToken},
        createImageThumbnails: true,
      },
    };
  },
  computed: {
    ...mapState({
      receiptPhoto: (state) => state.withdrawalRequest.receiptPhoto,
    }),
    loading: {
      get() {
        return this.$store.state.withdrawalRequest.loading;
      },
      set(value) {
        this.$store.commit('withdrawalRequest/SET_LOADING', value);
      },
    },
  },
  methods: {
    beforeOpen(event) {
      this.withdrawalRequest = event.params.withdrawalRequest;
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    triggerDropzone() {
      this.$refs.photoDropzone.dropzone.hiddenFileInput.click();
    },
    completed() {
      this.loading = false;
    },
    added(file) {
      this.loading = true;
      const dropzone = this.$refs.photoDropzone;
      const maxFile = 1;
      const listPhoto = this.receiptPhoto ? 1 : 0;
      const uploading = dropzone.getUploadingFiles().length;
      const queued = dropzone.getQueuedFiles().length;
      const totalPhoto = listPhoto + uploading + queued;
      this.addedPhoto = true;
      if (totalPhoto >= maxFile) {
        dropzone.removeFile(file);
        // eslint-disable-next-line no-undef
        this.loading = false;
        this.$swal(
          this.$t('errors.general.photo.maxTitle'),
          this.$i18n.t('errors.general.photo.maxMessage', {
            max: maxFile,
          }),
          'error',
        );
      }
    },
    // eslint-disable-next-line no-unused-vars
    sending(file, xhr, formData) {
      this.loading = true;
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.global.token}`);
    },
    success(file, response) {
      let img = file.previewElement.querySelector('img');
      this.$store.commit('withdrawalRequest/ADD_RECEIPT_PHOTO', {
        src: img.src,
        uuid: response.data.photo_uuid,
        isPrimary: false,
      });
      let dropzone = this.$refs.photoDropzone;
      dropzone.removeFile(file);
    },
    async accept() {
      try {
        await this.$store.dispatch('withdrawalRequest/disburse');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('withdrawalRequest.withdraw.successMsg'),
          'success',
        );
        this.close();
        await this.$store.dispatch('withdrawalRequest/getDetailInitData', this.$route.params.id);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>

<style scoped></style>
